import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";

// material
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

const FORMS = [
  { label: "Unlimited Jurisdiction (DISC-001)", value: "DISC-001" },
  { label: "Employment Form (DISC-002)", value: "DISC-002" },
  { label: "Unlawful Detainer Form (DISC-003)", value: "DISC-003" },
  { label: "Limited Jurisdiction (DISC-004)", value: "DISC-004" },
  { label: "Contruction Form (DISC-005)", value: "DISC-005" },
  { label: "Family Law Form (FL-145)", value: "FL-145" },
];

JudicialForms.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSelected: PropTypes.func,
};
export default function JudicialForms({ open, handleClose, handleSelected }) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "text.primary", m: 0, p: 2 }}>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          This will create a form with questions from standard Unlimited
          Jurisdiction, Limited Jurisdiction, Employment Form, Construction
          Form, Family Law form or Unlawful Detainer form. Use this feature if
          you want to manually select questions from the Judicial Council Form
          (instead of uploading a document with selected questions).{" "}
        </Typography>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 5, width: 1, px: { md: 5, xs: 2 } }}
        >
          {FORMS.map((form, index) => (
            <Button
              key={index}
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => handleSelected(form.value)}
            >
              {form.label}
            </Button>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
