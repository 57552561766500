import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Typography, Button, Stack } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

const NoticeStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  padding: 5,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
}));

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
export default function NotificationBar() {
  const pathname = useBasePath();
  const { user, isDemoDomain, isAuthenticated } = useAuth();
  const { subscription } = user;
  const [notice, setNotice] = useState({
    message: "",
    button: "",
    path: "",
    color: "",
  });

  useEffect(() => {
    setNotice({ message: "", button: "", path: "", color: "" });
    if (
      isAuthenticated &&
      subscription &&
      Object.keys(subscription).includes("status") &&
      parseInt(subscription.status, 10) === 0 &&
      subscription.planName &&
      pathname !== "/subscription"
    ) {
      setNotice({
        message: "Renew your subscription to continue enjoying the platform.",
        button: "Renew",
        path: PATH_DASHBOARD.business.subscription,
        color: "error",
      });
    }
  }, [subscription, pathname, isDemoDomain, isAuthenticated]);

  return (
    notice.message && (
      <NoticeStyle sx={{ bgcolor: `${notice.color}.light` }}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" sx={{ color: "common.black" }}>
            {notice.message}
          </Typography>
          <Button color="secondary" href={notice.path}>
            {notice.button}
          </Button>
        </Stack>
      </NoticeStyle>
    )
  );
}
