import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// material
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { FORM_TYPES } from "../../../utils/apis";
import FormUpload from "./FormUpload";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

CreateDiscovery.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function CreateDiscovery({ open, handleClose }) {
  const { getCases } = useAuth();
  const [cases, setCases] = useState([]);
  const [newCase, setCase] = useState({});
  const [discovery, setDiscovery] = useState("");
  const [openUpload, setOpenUploaded] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [clients, setClients] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleAdd = () => {
    if (discovery && newCase && selectedClient) {
      setOpenUploaded(true);
    } else {
      enqueueSnackbar("Please select all fields to proceed.", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    async function get() {
      try {
        setCases(await getCases());
      } catch (err) {
        enqueueSnackbar(
          err.message || "An error occured! Please try again later.",
          {
            variant: "error",
          }
        );
      }
    }
    if (open) {
      get();
    }
  }, [enqueueSnackbar, getCases, open]);
  return (
    <>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: "text.primary", m: 0, p: 2 }}>
          Add Discovery
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={handleClose}
            color="error"
          >
            <CancelIcon />
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 5 }}>
            Please select the form and the discovery type you'll like to add.
          </DialogContentText>
          <Stack spacing={2}>
            <Autocomplete
              fullWidth
              options={cases.filter((r) => r.cid !== "")}
              name="case"
              value={newCase}
              isOptionEqualToValue={(option, value) => option.cid === value.cid}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCase(newValue);
                  setClients(newValue.client || []);
                }
              }}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => (
                <TextField {...params} label="Select Case" margin="none" />
              )}
            />
            <TextField
              select
              sx={{ width: 1 }}
              label="Select Discovery"
              value={discovery}
              onChange={(e) => setDiscovery(e.target.value)}
            >
              {FORM_TYPES.map((form, index) => (
                <MenuItem key={index} value={form.type}>
                  {form.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              sx={{ width: 1, mt: 3 }}
              disabled={clients.length === 0}
              label="Select Client"
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
            >
              {clients.map((client, index) => (
                <MenuItem key={index} value={client.uid}>
                  {client.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Button
              fullWidth
              onClick={handleAdd}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
            <Button fullWidth onClick={handleClose} color="inherit">
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <FormUpload
        open={openUpload}
        handleClose={() => {
          setOpenUploaded(false);
          setDiscovery("");
          navigate(
            `${PATH_DASHBOARD.business.cases}/${newCase.cid}/${newCase.title}/discoveries`
          );
          setCase({});
          handleClose();
        }}
        type={discovery}
        client={selectedClient}
        caseId={newCase.cid || ""}
      />
    </>
  );
}
