import {
  Link as RouterLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { PATH_AUTH } from "../routes/paths";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------
const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  if (Object.prototype.hasOwnProperty.call(params, "*")) {
    delete params["*"];
  }
  return location.pathname.split("/").length - 1 === 1
    ? location.pathname
    : Object.values(params).reduce(
        (path, param) => path.replace(`/${param}`, ""),
        location.pathname
      );
};
export default function LogoOnlyLayout() {
  const pathname = useBasePath();
  return (
    <>
      <HeaderStyle>
        {pathname !== "/signup" &&
          pathname !== "/login" &&
          pathname !== "/login" &&
          (pathname === "/form" ? (
            <Logo disabledLink />
          ) : (
            <RouterLink to={PATH_AUTH.Login}>
              <Logo disabledLink />
            </RouterLink>
          ))}
      </HeaderStyle>
      <Outlet />
    </>
  );
}
