// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
  signup: path(ROOTS_AUTH, "signup"),
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verify: path(ROOTS_AUTH, "verify"),
  setPassword: path(ROOTS_AUTH, "set-password"),
  form: path(ROOTS_AUTH, "form"),
};

export const PATH_PAGE = {
  page404: "404",
  errorOccured: "500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    settings: path(ROOTS_DASHBOARD, "settings"),
    compliance: path(ROOTS_DASHBOARD, "settings/compliance"),
    account: path(ROOTS_DASHBOARD, "settings/account"),
  },
  misc: {
    log: path(ROOTS_DASHBOARD, "log"),
    support: path(ROOTS_DASHBOARD, "support"),
    sendSupport: path(ROOTS_DASHBOARD, "support/send"),
    onboarding: path(ROOTS_DASHBOARD, "onboarding"),
  },
  business: {
    clients: path(ROOTS_DASHBOARD, "clients"),
    discoveries: path(ROOTS_DASHBOARD, "discoveries"),
    addClient: path(ROOTS_DASHBOARD, "clients/add"),
    client: path(ROOTS_DASHBOARD, "client/"),
    users: path(ROOTS_DASHBOARD, "users"),
    addUser: path(ROOTS_DASHBOARD, "users/add"),
    user: path(ROOTS_DASHBOARD, "users/"),
    orders: path(ROOTS_DASHBOARD, "orders"),
    cases: path(ROOTS_DASHBOARD, "cases"),
    addCase: path(ROOTS_DASHBOARD, "cases/add"),
    objections: path(ROOTS_DASHBOARD, "objections"),
    templates: path(ROOTS_DASHBOARD, "templates"),
    addTemplate: path(ROOTS_DASHBOARD, "templates/add"),
    subscription: path(ROOTS_DASHBOARD, "subscription"),
    addObjection: path(ROOTS_DASHBOARD, "objections/add"),
  },
};

export const frontendPages = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/form",
  "/404",
];

export const subExemptPages = ["/", "/dashboard", "/profile", "/subscription"];
