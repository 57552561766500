// components
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ApprovalIcon from "@mui/icons-material/Approval";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupIcon from "@mui/icons-material/Group";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    subheader: "actions",
    items: [
      {
        title: "Clients",
        path: PATH_DASHBOARD.business.clients,
        icon: <ApprovalIcon />,
      },
      {
        title: "Cases",
        path: PATH_DASHBOARD.business.cases,
        icon: <LibraryBooksIcon />,
      },
      {
        title: "Discovery",
        path: PATH_DASHBOARD.business.discoveries,
        icon: <DynamicFormIcon />,
      },
    ],
  },
  {
    subheader: "administrative",
    items: [
      {
        title: "Users",
        path: PATH_DASHBOARD.business.users,
        icon: <GroupIcon />,
      },
      {
        title: "Objections",
        path: PATH_DASHBOARD.business.objections,
        icon: <MenuBookIcon />,
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsIcon />,
      },
      {
        title: "Custom Templates",
        path: PATH_DASHBOARD.business.templates,
        icon: <DashboardCustomizeIcon />,
      },
      {
        title: "Subscription",
        path: PATH_DASHBOARD.business.subscription,
        icon: <ShoppingCartIcon />,
      },
    ],
  },
];

export default sidebarConfig;
