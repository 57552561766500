import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { CheckSub } from "../utils/apis";
import SubscriptionError from "../pages/errors/SubscriptionError";
import LoggedOut from "../pages/errors/LoggedOut";
import useAuth from "../hooks/useAuth";
import { subExemptPages } from "../routes/paths";

// ----------------------------------------------------------------------

SubscriptionGuard.propTypes = {
  children: PropTypes.node,
};

export default function SubscriptionGuard({ children }) {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const checkSubscription = CheckSub();

  if (!isAuthenticated) {
    return <LoggedOut />;
  }
  if (checkSubscription !== 1 && !subExemptPages.includes(location.pathname)) {
    return <SubscriptionError />;
  }
  // eslint-disable-next-line
  return <>{children}</>;
}
