import { useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/authentication/Login";
import LoadingScreen from "../components/LoadingScreen";
import { frontendPages } from "../routes/paths";
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  if (Object.prototype.hasOwnProperty.call(params, "*")) {
    delete params["*"];
  }
  return location.pathname.split("/").length - 1 === 1
    ? location.pathname
    : Object.values(params).reduce(
        (path, param) => path.replace(`/${param}`, ""),
        location.pathname
      );
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, isVerifyEmail, isPopupOpen, user } =
    useAuth();
  const pathname = useBasePath();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (isInitialized) {
    if (!isAuthenticated && !frontendPages.includes(pathname) && !isPopupOpen) {
      return <Login />;
    }
    if ((!user || !user.subscription) && !frontendPages.includes(pathname)) {
      return <Login />;
    }

    if (isAuthenticated && !isVerifyEmail && pathname !== "/verify") {
      return <Navigate to="/verify" />;
    }

    if (isAuthenticated && frontendPages.includes(pathname)) {
      return <Navigate to="/" />;
    }

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
  } else {
    return <LoadingScreen />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  // eslint-disable-next-line
  return <>{children}</>;
}
