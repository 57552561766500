import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { frontendPages } from "./paths";

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = !frontendPages.includes(pathname);
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        {
          path: "settings",
          children: [
            { path: "", element: <Settings /> },
            { path: ":tab", element: <Settings /> },
          ],
        },
        { path: "orders", element: <Orders /> },
        { path: "subscription", element: <Subscription /> },
        { path: "discoveries", element: <Discoveries /> },
        {
          path: "cases",
          children: [
            { path: "", element: <Cases /> },
            { path: "add", element: <AddCase /> },
            { path: ":case/:name", element: <Case /> },
            { path: ":type", element: <Cases /> },
            { path: ":case/:name/:tab", element: <Case /> },
            { path: ":case/:name/:tab/:formId", element: <Case /> },
          ],
        },
        {
          path: "objections",
          children: [
            { path: "", element: <Objections /> },
            { path: "add", element: <AddObjection /> },
            { path: ":objection/:name", element: <AddObjection /> },
          ],
        },
        {
          path: "templates",
          children: [
            { path: "", element: <Templates /> },
            { path: "add", element: <AddTemplate /> },
            { path: ":template/:name", element: <AddTemplate /> },
          ],
        },
        {
          path: "support",
          children: [{ path: "send", element: <SendSupport /> }],
        },
        {
          path: "clients",
          children: [
            { path: "", element: <Clients /> },
            { path: "add", element: <AddClient /> },
            { path: ":client/:name", element: <Client /> },
          ],
        },
        {
          path: "users",
          children: [
            { path: "", element: <Users /> },
            { path: "add", element: <AddUser /> },
            { path: ":user/:name", element: <User /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        {
          path: "form",
          children: [
            { path: "", element: <Form /> },
            { path: ":formId", element: <Form /> },
            { path: ":formId/response", element: <Response /> },
          ],
        },
        { path: "forgot-password", element: <ForgotPassword /> },
        {
          path: "signup",
          element: (
            <AuthGuard>
              <Signup />
            </AuthGuard>
          ),
        },
        {
          path: "verify",
          element: (
            <AuthGuard>
              <VerifyCode />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// admin pages
const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));
const Discoveries = Loadable(
  lazy(() => import("../pages/dashboard/Discoveries"))
);
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Clients = Loadable(lazy(() => import("../pages/dashboard/Clients")));
const AddClient = Loadable(lazy(() => import("../pages/dashboard/AddClient")));
const Client = Loadable(lazy(() => import("../pages/dashboard/Client")));
const Users = Loadable(lazy(() => import("../pages/dashboard/Users")));
const AddUser = Loadable(lazy(() => import("../pages/dashboard/AddUser")));
const User = Loadable(lazy(() => import("../pages/dashboard/User")));
const Orders = Loadable(lazy(() => import("../pages/dashboard/Orders")));
const Objections = Loadable(
  lazy(() => import("../pages/dashboard/Objections"))
);
const Templates = Loadable(
  lazy(() => import("../pages/dashboard/CustomTemplates"))
);
const AddTemplate = Loadable(
  lazy(() => import("../pages/dashboard/AddTemplate"))
);
const AddObjection = Loadable(
  lazy(() => import("../pages/dashboard/AddObjection"))
);
const Cases = Loadable(lazy(() => import("../pages/dashboard/Cases")));
const Case = Loadable(lazy(() => import("../pages/dashboard/Case")));
const AddCase = Loadable(lazy(() => import("../pages/dashboard/AddCase")));
const Subscription = Loadable(
  lazy(() => import("../pages/dashboard/Subscription"))
);
const Signup = Loadable(lazy(() => import("../pages/authentication/Signup")));
const SendSupport = Loadable(
  lazy(() => import("../pages/dashboard/SendSupport"))
);
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));
const Form = Loadable(lazy(() => import("../pages/authentication/Form")));
const Response = Loadable(
  lazy(() => import("../pages/authentication/Response"))
);

// auth
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/Verify"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
