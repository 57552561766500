import PropTypes from "prop-types";
import { useState, useCallback, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// material
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
  Box,
  Checkbox,
  Divider,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import useAuth from "../../../hooks/useAuth";
import { FORM_TYPES } from "../../../utils/apis";
import { UploadSingleFile } from "../../upload";
import JudicialForms from "./JudicialForms";

FormUpload.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  client: PropTypes.string,
  caseId: PropTypes.string,
};
export default function FormUpload({
  open,
  handleClose,
  type,
  client,
  caseId,
}) {
  const { uploadFormFile, setFormFile, processFile, saveFormFile, deleteForm } =
    useAuth();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentEdit, setCurrentEdit] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [currentStep, setCurrentStep] = useState("upload");
  const [stopProcessing, setStopProcessing] = useState(false);
  const typeData = type ? FORM_TYPES[parseInt(type, 10)] : {};

  const [openJudicial, setOpenJudicial] = useState(false);
  const [isJudicial, setIsJudicial] = useState("");
  const [formId, setFormId] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = (index, question, subQuestionId, innerSubId, item) => {
    setQuestions((prev) =>
      prev.map((q, i) => {
        if (i === index) {
          if (typeof q === "string") {
            return question;
          }
          if (subQuestionId === undefined) {
            q.main = question;
          }
          if (
            subQuestionId !== undefined &&
            (innerSubId === undefined || innerSubId === -1)
          ) {
            // main question
            q.sub[subQuestionId][item] = question;
            return q;
          }
          if (innerSubId !== undefined && innerSubId > -1) {
            // update subquestion
            q.sub[subQuestionId].innerSub[innerSubId][item] = question;
            return q;
          }
        }
        return q;
      })
    );
  };
  const handleDelete = (index, subQuestionId, innerSubId) => {
    if (subQuestionId === undefined) {
      setQuestions((prev) => prev.filter((q, i) => i !== index));
      setCurrentEdit((prev) => (prev === String(index) ? "" : prev));
      return;
    }
    if (subQuestionId !== undefined && innerSubId === undefined) {
      // subquestion
      setQuestions((prev) =>
        prev
          .map((q, i) => {
            if (i === index) {
              const sub = q.sub.filter((s, ind) => ind !== subQuestionId);
              q.sub = sub;
            }
            return q;
          })
          .filter((q) => q.main || (q.sub && q.sub.length > 0))
      );
      setCurrentEdit((prev) =>
        prev === `${index}-${subQuestionId}` ? "" : prev
      );
      return;
    }
    if (innerSubId !== undefined) {
      // inner subquestion
      setQuestions((prev) =>
        prev.map((q, i) => {
          if (i === index) {
            const innerSub = q.sub[subQuestionId].innerSub.filter(
              (s, ind) => ind !== innerSubId
            );
            q.sub[subQuestionId].innerSub = innerSub;
          }
          setCurrentEdit((prev) =>
            prev === `${index}-${subQuestionId}-${innerSubId}` ? "" : prev
          );
          return q;
        })
      );
    }
  };
  const handleAdd = (index, subQuestionId, innerSubId) => {
    if (subQuestionId === undefined) {
      setQuestions((prev) => [
        ...prev.slice(0, index + 1),
        typeof prev[0] === "string"
          ? ""
          : {
              main: "",
              isChecked: true,
              sub: [
                {
                  id: "",
                  isChecked: true,
                  value: "",
                  innerSub: [
                    {
                      id: "",
                      isChecked: true,
                      value: "",
                    },
                  ],
                },
              ],
            },
        ...prev.slice(index + 1),
      ]);
      setCurrentEdit(String(index + 1));
      return;
    }
    if (subQuestionId !== undefined && innerSubId === undefined) {
      setQuestions((prev) =>
        prev.map((q, i) => {
          if (i === index) {
            q.sub = [
              ...q.sub.slice(0, subQuestionId + 1),
              {
                id: "",
                isChecked: true,
                value: "",
                innerSub: [
                  {
                    id: "",
                    isChecked: true,
                    value: "",
                  },
                ],
              },
              ...q.sub.slice(subQuestionId + 1),
            ];
          }
          return q;
        })
      );
      setCurrentEdit(`${index}-${subQuestionId + 1}`);
      return;
    }
    if (innerSubId !== undefined) {
      setQuestions((prev) =>
        prev.map((q, i) => {
          if (i === index) {
            q.sub[subQuestionId].innerSub = [
              ...q.sub[subQuestionId].innerSub.slice(0, innerSubId + 1),
              {
                id: "",
                isChecked: true,
                value: "",
              },
              ...q.sub[subQuestionId].innerSub.slice(innerSubId + 1),
            ];
          }
          return q;
        })
      );
      setCurrentEdit(`${index}-${subQuestionId}-${innerSubId + 1}`);
    }
  };
  const handleRemovePerson = (index, subQuestionId, innerSubId) => {
    if (subQuestionId !== undefined && index !== undefined) {
      setQuestions((prev) =>
        prev.map((question, questionIndex) => {
          if (questionIndex === index) {
            question.sub = question.sub.map((sub, subIndex) => {
              if (subQuestionId === subIndex) {
                const { innerSub } = sub;
                innerSub.splice(innerSubId, 1);
                sub.innerSub = innerSub;
              }
              return sub;
            });
          }
          return question;
        })
      );
    }
  };
  const handleAddPerson = (index, subQuestionId) => {
    if (subQuestionId !== undefined && index !== undefined) {
      setQuestions((prev) =>
        prev.map((question, questionIndex) => {
          if (questionIndex === index) {
            question.sub = question.sub.map((sub, subIndex) => {
              if (subQuestionId === subIndex) {
                const { innerSub } = sub;
                innerSub.push(innerSub[0]);
                sub.innerSub = innerSub;
              }
              return sub;
            });
          }
          return question;
        })
      );
    }
  };
  const handleDropSingleFile = useCallback(
    (acceptedFiles) => {
      const upload = async (file) => {
        try {
          // upload to server
          setFile("");
          const formData = new FormData();
          formData.append("form_file", file);
          setDisableSubmit(true);
          enqueueSnackbar(`Uploading file... Please wait.`, {
            variant: "success",
          });
          setMessage("Uploading file... Please wait.");
          const fileData = await uploadFormFile(formData);
          enqueueSnackbar(`File uploaded`, { variant: "success" });
          setMessage("File uploaded successfully!");
          setFile(fileData.data);
          setFileName(fileData.fileName);
        } catch (err) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
        setDisableSubmit(false);
      };

      const file = acceptedFiles[0];
      if (file) {
        setFile({
          ...file,
          preview: URL.createObjectURL(file),
        });
        upload(file);
      }
    },
    [uploadFormFile, enqueueSnackbar, setDisableSubmit]
  );
  const handleProcess = useCallback(() => {
    const get = async () => {
      try {
        const { data, form } = await processFile(
          caseId,
          type,
          client,
          formId,
          isJudicial,
          fileName
        );
        setQuestions(data);
        if (!stopProcessing) {
          setCurrentStep("questions");
        }
        setFormId(form);
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        setCurrentStep("upload");
      }
    };
    if (!stopProcessing && currentStep === "process") {
      get();
    }
  }, [
    caseId,
    client,
    formId,
    enqueueSnackbar,
    processFile,
    stopProcessing,
    fileName,
    type,
    isJudicial,
    currentStep,
  ]);
  const handleUpload = async () => {
    if (file && fileName) {
      try {
        setLoading(true);
        setFormId(await setFormFile(caseId, type, client, file, fileName));
        setCurrentStep("process");
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(
        "Please select a valid file and enter the filename to proceed.",
        {
          variant: "error",
        }
      );
    }
  };
  const handleSave = async () => {
    try {
      const ques = [...questions];
      const selectedQuestions =
        typeof questions[0] === "string"
          ? ques
          : ques
              .map((q) => {
                q.sub = q.sub.filter((s) => s.isChecked);
                return q;
              })
              .filter((q) => q.sub && q.sub.length > 0);
      setLoading(true);
      await saveFormFile(caseId, type, client, formId, selectedQuestions);
      enqueueSnackbar("Form saved successfully!", {
        variant: "success",
      });
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  const onClose = () => {
    setFile("");
    setLoading(false);
    setMessage("");
    setFileName("");
    setDisableSubmit(true);
    setCurrentStep("upload");
    setQuestions([]);
    handleClose();
  };
  const onCancel = async (isUpload) => {
    if (formId) {
      try {
        setLoading(true);
        await deleteForm(formId);
        if (isUpload) {
          setCurrentStep("upload");
        } else {
          onClose();
        }
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      }
      setLoading(false);
    } else if (isUpload) {
      setCurrentStep("upload");
    } else {
      onClose();
    }
  };
  const handleJudicialSelected = (form) => {
    setIsJudicial(form);
    setCurrentStep("process");
    setOpenJudicial(false);
  };
  useEffect(() => {
    handleProcess();
  }, [handleProcess]);
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullScreen={currentStep === "questions"}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <Stack
          direction="column"
          alignItems="start"
          justifyContent="start"
          spacing={4}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={() => onCancel(false)}
            color="error"
          >
            <CancelIcon />
          </IconButton>
          <Typography variant="subtitle2" sx={{ m: 0, p: 2 }}>
            {currentStep === "questions"
              ? "Please review and confirm that the questions below are accurate. You can use the 'Edit' button to make modification. Click on 'Save Form' to proceed."
              : `Upload File for ${(typeData && typeData.title) || ""}`}
            .
          </Typography>
        </Stack>
        <DialogContent>
          {currentStep === "process" && (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Typography variant="subtitle2" color="secondary">
                Processing and extracting questions from the file...
              </Typography>
              <CircularProgress />
            </Stack>
          )}
          {currentStep === "upload" && (
            <>
              {(type === "4" && (
                <Box sx={{ width: 1, margin: "0 auto", textAlign: "center" }}>
                  <TextField
                    sx={{ mb: 2 }}
                    autoFocus
                    fullWidth
                    label="Enter file name"
                    size="small"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      if (fileName) {
                        handleJudicialSelected("General Initial Disclosure");
                      } else {
                        enqueueSnackbar(
                          `Please enter a valid file name to proceed.`,
                          {
                            variant: "warning",
                          }
                        );
                      }
                    }}
                  >
                    Use General Initial Disclosure
                  </LoadingButton>
                </Box>
              )) || (
                <UploadSingleFile
                  title="Upload .pdf file"
                  accept={{ "application/pdf": [".pdf"] }}
                  message={message}
                  onDrop={handleDropSingleFile}
                />
              )}

              {file && (
                <TextField
                  sx={{ mt: 2 }}
                  autoFocus
                  fullWidth
                  label="Enter file name"
                  size="small"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
              )}
            </>
          )}
          {currentStep === "questions" && (
            <>
              {/* FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (type === "0") {
                          const que = [...questions];
                          const val = e.target.checked;
                          que.map((q) => q.sub.map((s) => (s.isChecked = val)));
                          setQuestions(que);
                        } else {
                          setSQuestions(questions);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label="Select All"
                  sx={{ ml: type === "0" ? 4 : 0, mb: 2 }}
                />
                 */}

              <Stack direction="column" spacing={5} sx={{ width: 1 }}>
                {questions.map((question, index) => (
                  <Stack key={index} direction="row" spacing={1}>
                    {typeof question === "string" && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                        spacing={1}
                        sx={{ width: 1 }}
                      >
                        {isEdit && (
                          <Stack direction="row">
                            <Tooltip
                              title="Delete Question"
                              onClick={() => handleDelete(index)}
                            >
                              <IconButton color="error" size="small">
                                <DeleteOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Add Question below"
                              onClick={() => handleAdd(index)}
                            >
                              <IconButton color="primary" size="small">
                                <AddOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Edit this Question"
                              onClick={() =>
                                setCurrentEdit((prev) =>
                                  prev === String(index) ? "" : String(index)
                                )
                              }
                            >
                              <IconButton
                                color={
                                  currentEdit === String(index)
                                    ? "success"
                                    : "info"
                                }
                                size="small"
                              >
                                {currentEdit === String(index) ? (
                                  <DoneOutlineOutlinedIcon fontSize="small" />
                                ) : (
                                  <EditOutlinedIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        )}
                        {/* <Checkbox
                            checked={sQuestions.includes(question)}
                            sx={{ alignSelf: "start" }}
                            onChange={(event) => {
                              const sque = sQuestions;
                              if (event.target.checked) {
                                if (!sque.includes(question)) {
                                  setSQuestions((prevState) => [
                                    ...prevState,
                                    question,
                                  ]);
                                }
                              } else {
                                const index = sque.indexOf(question.text);
                                if (index > -1) {
                                  sque.splice(index, 1);
                                  setSQuestions((prevState) => [...sque]);
                                }
                              }
                            }}
                          /> */}
                        <Typography variant="body2">{index + 1}.</Typography>
                        {isEdit && currentEdit === String(index) ? (
                          <TextField
                            value={question}
                            size="small"
                            fullWidth
                            onChange={(e) => {
                              handleEdit(index, e.target.value || "");
                            }}
                            label="Question Text"
                            multiline
                            InputLabelProps={{ shrink: true }}
                          />
                        ) : (
                          <Typography variant="body2">{question}</Typography>
                        )}
                      </Stack>
                    )}
                    {typeof question === "object" && (
                      <Stack
                        direction="column"
                        alignItems="start"
                        sx={{ width: 1 }}
                      >
                        {question.sub && (
                          <Stack
                            direction="column"
                            sx={{ ml: 2, width: 1 }}
                            spacing={5}
                          >
                            {question.sub.length === 0 && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="start"
                              >
                                <Typography variant="body2">
                                  {index + 1}
                                </Typography>
                                {isEdit && (
                                  <Stack direction="row">
                                    <Tooltip
                                      title="Delete Question"
                                      onClick={() => handleDelete(index)}
                                    >
                                      <IconButton color="error" size="small">
                                        <DeleteOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Add Question below"
                                      onClick={() => handleAdd(index)}
                                    >
                                      <IconButton color="primary" size="small">
                                        <AddOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Edit this Question"
                                      onClick={() =>
                                        setCurrentEdit((prev) =>
                                          prev === String(index)
                                            ? ""
                                            : String(index)
                                        )
                                      }
                                    >
                                      <IconButton
                                        color={
                                          currentEdit === String(index)
                                            ? "success"
                                            : "info"
                                        }
                                        size="small"
                                      >
                                        {currentEdit === String(index) ? (
                                          <DoneOutlineOutlinedIcon fontSize="small" />
                                        ) : (
                                          <EditOutlinedIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                )}
                                <Checkbox
                                  checked={question.isChecked}
                                  onChange={(event) => {
                                    const que = [...questions];
                                    que[index].isChecked = event.target.checked;
                                    setQuestions(que);
                                  }}
                                />
                                {isEdit && currentEdit === String(index) ? (
                                  <TextField
                                    value={question.main}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => {
                                      handleEdit(index, e.target.value || "");
                                    }}
                                    label="Question Text"
                                    multiline
                                    InputLabelProps={{ shrink: true }}
                                  />
                                ) : (
                                  <Typography variant="body2">
                                    {question.main}
                                  </Typography>
                                )}
                              </Stack>
                            )}
                            {question.sub.map((subquestion, i) => (
                              <Stack
                                key={i}
                                spacing={isEdit ? 3 : 1}
                                sx={{ width: 1 }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="start"
                                  spacing={1}
                                  sx={{ width: 1 }}
                                >
                                  <Checkbox
                                    checked={subquestion.isChecked}
                                    onChange={(event) => {
                                      const que = [...questions];
                                      que[index].sub[i].isChecked =
                                        event.target.checked;
                                      setQuestions(que);
                                    }}
                                  />
                                  {isEdit && (
                                    <Stack direction="row">
                                      <Tooltip
                                        title="Delete Question"
                                        onClick={() => handleDelete(index, i)}
                                      >
                                        <IconButton color="error" size="small">
                                          <DeleteOutlinedIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="Add Question below"
                                        onClick={() => handleAdd(index, i)}
                                      >
                                        <IconButton
                                          color="primary"
                                          size="small"
                                        >
                                          <AddOutlinedIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="Edit this Question"
                                        onClick={() =>
                                          setCurrentEdit((prev) =>
                                            prev === `${index}-${i}`
                                              ? ""
                                              : `${index}-${i}`
                                          )
                                        }
                                      >
                                        <IconButton
                                          color={
                                            currentEdit === `${index}-${i}`
                                              ? "success"
                                              : "info"
                                          }
                                          size="small"
                                        >
                                          {currentEdit === `${index}-${i}` ? (
                                            <DoneOutlineOutlinedIcon fontSize="small" />
                                          ) : (
                                            <EditOutlinedIcon fontSize="small" />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </Stack>
                                  )}
                                  {isEdit && currentEdit === `${index}-${i}` ? (
                                    <TextField
                                      value={subquestion.id}
                                      size="small"
                                      sx={{ maxWidth: 150 }}
                                      fullWidth
                                      onChange={(e) => {
                                        handleEdit(
                                          index,
                                          e.target.value || "",
                                          i,
                                          -1,
                                          "id"
                                        );
                                      }}
                                      label="Question Number"
                                      multiline
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  ) : (
                                    <Typography variant="body2">
                                      {subquestion.id}
                                    </Typography>
                                  )}
                                  {isEdit && currentEdit === `${index}-${i}` ? (
                                    <TextField
                                      value={subquestion.value}
                                      size="small"
                                      fullWidth
                                      onChange={(e) => {
                                        handleEdit(
                                          index,
                                          e.target.value || "",
                                          i,
                                          -1,
                                          "value"
                                        );
                                      }}
                                      label="Question Text"
                                      multiline
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  ) : (
                                    <Typography variant="body2">
                                      {subquestion.value}
                                    </Typography>
                                  )}
                                </Stack>
                                {subquestion.innerSub && (
                                  <Stack
                                    direction="column"
                                    spacing={2}
                                    sx={{ pl: 7 }}
                                  >
                                    {Array.isArray(subquestion.innerSub[0]) && (
                                      <Typography variant="body2">
                                        <strong>NOTE:</strong> If you have more
                                        than one person, please click the 'Add
                                        New' button at the end of the questions
                                        to record for each of them.
                                      </Typography>
                                    )}
                                    {subquestion.innerSub.map(
                                      (innersubquestion, ind) =>
                                        (Array.isArray(innersubquestion) && (
                                          <Stack
                                            direction="column"
                                            spacing={1}
                                            key={ind}
                                          >
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              divider={
                                                <Divider
                                                  flexItem
                                                  orientation="vertical"
                                                />
                                              }
                                              spacing={2}
                                            >
                                              <Typography variant="subtitle2">
                                                Person {ind + 1}
                                              </Typography>
                                              {ind > 0 && (
                                                <Button
                                                  color="error"
                                                  size="small"
                                                  onClick={() =>
                                                    handleRemovePerson(
                                                      index,
                                                      i,
                                                      ind
                                                    )
                                                  }
                                                >
                                                  Remove
                                                </Button>
                                              )}
                                            </Stack>
                                            {innersubquestion.map(
                                              (innerSub, innerSubIndex) => (
                                                <Stack
                                                  direction="row"
                                                  alignItems="center"
                                                  spacing={1}
                                                  key={innerSubIndex}
                                                >
                                                  {isEdit && (
                                                    <Stack
                                                      direction="row"
                                                      alignSelf="start"
                                                    >
                                                      <Tooltip
                                                        title="Delete Question"
                                                        onClick={() =>
                                                          handleDelete(
                                                            index,
                                                            i,
                                                            ind
                                                          )
                                                        }
                                                      >
                                                        <IconButton
                                                          color="error"
                                                          size="small"
                                                        >
                                                          <DeleteOutlinedIcon fontSize="small" />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip
                                                        title="Add Question below"
                                                        onClick={() =>
                                                          handleAdd(
                                                            index,
                                                            i,
                                                            ind
                                                          )
                                                        }
                                                      >
                                                        <IconButton
                                                          color="primary"
                                                          size="small"
                                                        >
                                                          <AddOutlinedIcon fontSize="small" />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip
                                                        title="Edit this Question"
                                                        onClick={() =>
                                                          setCurrentEdit(
                                                            (prev) =>
                                                              prev ===
                                                              `${index}-${i}-${ind}`
                                                                ? ""
                                                                : `${index}-${i}-${ind}`
                                                          )
                                                        }
                                                      >
                                                        <IconButton
                                                          color={
                                                            currentEdit ===
                                                            `${index}-${i}-${ind}`
                                                              ? "success"
                                                              : "info"
                                                          }
                                                          size="small"
                                                        >
                                                          {currentEdit ===
                                                          `${index}-${i}-${ind}` ? (
                                                            <DoneOutlineOutlinedIcon fontSize="small" />
                                                          ) : (
                                                            <EditOutlinedIcon fontSize="small" />
                                                          )}
                                                        </IconButton>
                                                      </Tooltip>
                                                    </Stack>
                                                  )}
                                                  {isEdit &&
                                                  currentEdit ===
                                                    `${index}-${i}-${ind}` ? (
                                                    <TextField
                                                      value={innerSub.id}
                                                      size="small"
                                                      fullWidth
                                                      sx={{ maxWidth: 150 }}
                                                      onChange={(e) => {
                                                        handleEdit(
                                                          index,
                                                          e.target.value || "",
                                                          i,
                                                          ind,
                                                          "id"
                                                        );
                                                      }}
                                                      label="Question Number"
                                                      multiline
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                  ) : (
                                                    <Typography variant="body2">
                                                      {innerSub.id}
                                                    </Typography>
                                                  )}
                                                  {isEdit &&
                                                  currentEdit ===
                                                    `${index}-${i}-${ind}` ? (
                                                    <TextField
                                                      value={innerSub.value}
                                                      size="small"
                                                      fullWidth
                                                      onChange={(e) => {
                                                        handleEdit(
                                                          index,
                                                          e.target.value || "",
                                                          i,
                                                          ind,
                                                          "value"
                                                        );
                                                      }}
                                                      label="Question Text"
                                                      multiline
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                  ) : (
                                                    <Typography variant="body2">
                                                      {innerSub.value}
                                                    </Typography>
                                                  )}
                                                </Stack>
                                              )
                                            )}
                                          </Stack>
                                        )) || (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            key={ind}
                                          >
                                            {isEdit && (
                                              <Stack
                                                direction="row"
                                                alignSelf="start"
                                              >
                                                <Tooltip
                                                  title="Delete Question"
                                                  onClick={() =>
                                                    handleDelete(index, i, ind)
                                                  }
                                                >
                                                  <IconButton
                                                    color="error"
                                                    size="small"
                                                  >
                                                    <DeleteOutlinedIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                  title="Add Question below"
                                                  onClick={() =>
                                                    handleAdd(index, i, ind)
                                                  }
                                                >
                                                  <IconButton
                                                    color="primary"
                                                    size="small"
                                                  >
                                                    <AddOutlinedIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                  title="Edit this Question"
                                                  onClick={() =>
                                                    setCurrentEdit((prev) =>
                                                      prev ===
                                                      `${index}-${i}-${ind}`
                                                        ? ""
                                                        : `${index}-${i}-${ind}`
                                                    )
                                                  }
                                                >
                                                  <IconButton
                                                    color={
                                                      currentEdit ===
                                                      `${index}-${i}-${ind}`
                                                        ? "success"
                                                        : "info"
                                                    }
                                                    size="small"
                                                  >
                                                    {currentEdit ===
                                                    `${index}-${i}-${ind}` ? (
                                                      <DoneOutlineOutlinedIcon fontSize="small" />
                                                    ) : (
                                                      <EditOutlinedIcon fontSize="small" />
                                                    )}
                                                  </IconButton>
                                                </Tooltip>
                                              </Stack>
                                            )}
                                            {isEdit &&
                                            currentEdit ===
                                              `${index}-${i}-${ind}` ? (
                                              <TextField
                                                value={innersubquestion.id}
                                                size="small"
                                                fullWidth
                                                sx={{ maxWidth: 150 }}
                                                onChange={(e) => {
                                                  handleEdit(
                                                    index,
                                                    e.target.value || "",
                                                    i,
                                                    ind,
                                                    "id"
                                                  );
                                                }}
                                                label="Question Number"
                                                multiline
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            ) : (
                                              <Typography variant="body2">
                                                {innersubquestion.id}
                                              </Typography>
                                            )}
                                            {isEdit &&
                                            currentEdit ===
                                              `${index}-${i}-${ind}` ? (
                                              <TextField
                                                value={innersubquestion.value}
                                                size="small"
                                                fullWidth
                                                onChange={(e) => {
                                                  handleEdit(
                                                    index,
                                                    e.target.value || "",
                                                    i,
                                                    ind,
                                                    "value"
                                                  );
                                                }}
                                                label="Question Text"
                                                multiline
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            ) : (
                                              <Typography variant="body2">
                                                {innersubquestion.value}
                                              </Typography>
                                            )}
                                          </Stack>
                                        )
                                    )}
                                    {Array.isArray(subquestion.innerSub[0]) && (
                                      <Button
                                        color="info"
                                        size="small"
                                        variant="contained"
                                        sx={{ alignSelf: "start", mt: 2 }}
                                        onClick={() =>
                                          handleAddPerson(index, i)
                                        }
                                      >
                                        ADD NEW
                                      </Button>
                                    )}
                                  </Stack>
                                )}
                              </Stack>
                            ))}
                          </Stack>
                        )}
                      </Stack>
                    )}
                  </Stack>
                ))}
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", mt: 2 }}
          >
            {currentStep === "upload" && type === "0" && (
              <Button
                variant="contained"
                size="small"
                color="inherit"
                onClick={() => setOpenJudicial(true)}
              >
                Use Judicial Council Form
              </Button>
            )}
            {/* currentStep === "upload" && (
              <Button variant="contained" color="inherit" size="small">
                Use Saved Template
              </Button>
            ) */}
            <LoadingButton
              onClick={() => {
                if (currentStep === "upload") {
                  setStopProcessing(false);
                  handleUpload();
                } else if (currentStep === "process") {
                  setStopProcessing(true);
                  setCurrentStep("upload");
                } else if (currentStep === "questions") {
                  setStopProcessing(false);
                  handleSave();
                }
              }}
              variant="contained"
              size="small"
              loading={loading}
              disabled={disableSubmit && currentStep === "upload"}
              color={
                (currentStep === "upload" && "primary") ||
                (currentStep === "process" && "error") ||
                (currentStep === "questions" && "primary")
              }
            >
              {(currentStep === "upload" && "Upload") ||
                (currentStep === "process" && "Cancel Process") ||
                (currentStep === "questions" && "Save Form")}
            </LoadingButton>
            {currentStep === "questions" && parseInt(type, 10) !== 4 && (
              <Button
                onClick={() => setIsEdit(!isEdit)}
                variant="contained"
                color="error"
                size="small"
              >
                {isEdit ? "Cancel Edit" : "Edit"}
              </Button>
            )}
            {currentStep === "questions" && (
              <Button
                onClick={() => onCancel(true)}
                variant="contained"
                color="inherit"
                size="small"
              >
                Cancel
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
      <JudicialForms
        open={openJudicial}
        handleClose={() => setOpenJudicial(false)}
        handleSelected={handleJudicialSelected}
      />
    </>
  );
}
