import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  objections: [],
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "objection",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.objections = action.payload.data;
      state.total = parseInt(action.payload.total, 10);
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getObjections(
  search,
  state,
  type,
  page,
  perpage,
  order,
  sortBy
) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.objections, {
        search,
        status: "",
        state,
        type,
        page,
        perpage,
        order,
        sortBy,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your objections at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
