import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH, removeHTMLEntities } from "../utils/apis";
import { setSession, setHeader } from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerifyEmail: false,
  isPopupOpen: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isVerifyEmail, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerifyEmail,
      isInitialized: true,
      isPopupOpen: false,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isVerifyEmail } = action.payload;
    return {
      ...state,
      isVerifyEmail,
      isAuthenticated: true,
      isPopupOpen: false,
      user,
    };
  },
  LOGOUT: (state, action) => {
    const { isPopUp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      isPopupOpen: isPopUp,
      user: null,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerifyEmail: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let isVerifyEmail = false;
    let user = {};
    try {
      const response = await axios.post(API_PATH.profile, {
        get: "",
      });
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        isAuthenticated = Boolean(user.user.uid);
        // eslint-disable-next-line
        if (isAuthenticated && parseInt(user.practice.isVerified, 10) === 1) {
          isVerifyEmail = true;
        }
      }

      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isVerifyEmail,
        user,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async (isPopUp) => {
    if (isPopUp) {
      const currentUser = state.user.user;
      localStorage.setItem("popupName", currentUser.fname);
      localStorage.setItem("popupEmail", currentUser.email);
    } else {
      localStorage.removeItem("popupName");
      localStorage.removeItem("popupEmail");
    }
    logDevice(0);
    setSession(null);
    dispatch({ type: "LOGOUT", payload: { isPopUp } });
  };
  const login = async (username, password) => {
    const response = await axios.post(API_PATH.login, {
      username,
      password,
      method: "email",
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      const isVerifyEmail = parseInt(data.practice.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail },
      });
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your username or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
    if (status === "loggedin") {
      throw new Error(
        "You cannot login to multiple devices at the same time. Please logout from the previous device and try again."
      );
    }
  };
  const signup = async (values) => {
    const response = await axios.post(API_PATH.signup, values);
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      const isVerifyEmail = parseInt(data.user.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail },
      });
    }
    if (status === "practice_taken") {
      throw new Error(
        "The Law firm you entered is already in use. Please confirm and try again or login to your practice."
      );
    }
    if (status === "email_taken") {
      throw new Error(
        "The email address you entered has already been registered. Please enter a new one or login to your account."
      );
    }
    if (status === "invalid_practice") {
      throw new Error(
        "The Law firm is invalid! Please enter a valid one to proceed."
      );
    }
    throw new Error(
      "An error occured while attempting to create your account. Please try again later."
    );
  };
  const logDevice = async (status) => {
    axios.post(API_PATH.login, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      status,
      type: 0,
    });
  };

  const resetPassword = async (code, password) => {
    const response = await axios.post(API_PATH.forgot, {
      code,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your password reset code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your password reset code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your password reset code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while resetting your password! Please confirm and try again."
        );
      }
    }
  };
  const forgotPassword = async (email) => {
    const response = await axios.post(API_PATH.forgot, {
      email,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Your email address or username is incorrect! Please confirm and try again."
      );
    }
  };
  const saveToken = async (saveToken) => {
    await axios.post(API_PATH.notifications, { saveToken, device: getUA });
  };
  const verifyAccount = async (code) => {
    const response = await axios.post(API_PATH.verify, { code });
    const { status, data } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your verification code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your verification code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your verification code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while verifying your account! Please confirm and try again."
        );
      }
    } else {
      dispatch({ type: "VERIFY", payload: { user: data } });
    }
  };
  const sendVerifyCode = async () => {
    const response = await axios.post(API_PATH.verify, { request: "" });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };
  const verifyForm = async (code, uid, formId) => {
    const response = await axios.post(API_PATH.verify, {
      code_form: code,
      uid,
      formId,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your verification code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your verification code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your verification code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while verifying your account! Please confirm and try again."
        );
      }
    }
  };
  const sendFormVerifyCode = async (uid, formId) => {
    const response = await axios.post(API_PATH.verify, {
      requestForm: "",
      uid,
      formId,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const updateSettings = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      update_practice: "",
      ...values,
      photoURL: "",
      state: values.state ? values.state.name : "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating your profile. Please try again later."
      );
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const addSupport = async (values) => {
    const response = await axios.post(API_PATH.support, { add: "", ...values });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later."
      );
    }
  };
  const requestLink = async () => {
    const response = await axios.post(API_PATH.settings, { getLink: "" });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    } else {
      throw new Error(
        "Something went wrong while generating your referral link! Please try again later."
      );
    }
  };
  const addObjection = async (values) => {
    const response = await axios.post(API_PATH.objections, {
      add: "",
      ...values,
      state: values.state ? values.state.name : "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "title_exists") {
      throw new Error(
        "An objection has already been added with the title specified! Please enter a new title to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const addTemplate = async (values) => {
    const response = await axios.post(API_PATH.templates, {
      add: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A template has already been added with the file name specified! Please enter a new file name to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };

  const addClient = async (values) => {
    const response = await axios.post(API_PATH.clients, { add: "", ...values });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A client has already been added with the name specified! Please enter a new name to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const getClient = async (client) => {
    const response = await axios.post(API_PATH.clients, { client });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching your client! Please try again later."
    );
  };
  const updateClientStatus = async (newStatus, client) => {
    const response = await axios.post(API_PATH.clients, {
      update_status: client,
      newStatus,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_client") {
      throw new Error(
        "The client you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update client's status! Please try again later."
      );
    }
  };
  const updateClient = async (values, client) => {
    const response = await axios.post(API_PATH.clients, {
      update: client,
      ...values,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_client") {
      throw new Error(
        "The client you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update client's details! Please try again later."
      );
    }
  };
  const deleteClient = async (remove) => {
    const response = await axios.post(API_PATH.clients, {
      remove,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_client") {
      throw new Error(
        "The client you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to delete client! Please try again later."
      );
    }
  };

  const addUser = async (values) => {
    const response = await axios.post(API_PATH.users, {
      add: "",
      ...values,
      role: values.role.rid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "email_exists") {
      throw new Error(
        "A user has already been added with the email address specified! Please enter a new name to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const getUser = async (user) => {
    const response = await axios.post(API_PATH.users, { user });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching user! Please try again later."
    );
  };
  const updateUserStatus = async (newStatus, user) => {
    const response = await axios.post(API_PATH.users, {
      update_status: user,
      newStatus,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_user") {
      throw new Error(
        "The user you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update user's status! Please try again later."
      );
    }
  };
  const updateUserPassword = async (password, user) => {
    const response = await axios.post(API_PATH.users, {
      update_password: user,
      password,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_user") {
      throw new Error(
        "The user you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update user's password! Please try again later."
      );
    }
  };
  const updateUser = async (values, user) => {
    const response = await axios.post(API_PATH.users, {
      update: user,
      ...values,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_user") {
      throw new Error(
        "The user you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update user's details! Please try again later."
      );
    }
  };
  const deleteUser = async (remove) => {
    const response = await axios.post(API_PATH.users, {
      remove,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_user") {
      throw new Error(
        "The user you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "cannot_delete") {
      throw new Error(
        "The user you selected cannot be removed! Please select another to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to delete user! Please try again later."
      );
    }
  };

  const addCase = async (values) => {
    const attorney = values.attorney
      ? JSON.stringify(values.attorney.map((attorney) => attorney.uid))
      : "";
    const client = values.client
      ? JSON.stringify(values.client.map((cl) => cl.uid))
      : "";

    const response = await axios.post(API_PATH.cases, {
      add: "",
      ...values,
      state: (values.state && values.state.name) || "",
      county:
        (values.county &&
          Object.values(values.county).length > 0 &&
          Object.values(values.county)[0]) ||
        "",
      attorney,
      client,
      oppState: (values.oppState && values.oppState.name) || "",
      plaintiff: JSON.stringify(values.plaintiff),
      defendant: JSON.stringify(values.defendant),
      oppCity:
        (values.oppCity &&
          Object.values(values.oppCity).length > 0 &&
          Object.values(values.oppCity)[0]) ||
        "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A case has already been added with the name specified! Please enter a new name to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const updateCaseOpposingCouncil = async (values, caseId, isAdd) => {
    const response = await axios.post(API_PATH.cases, {
      update_opposing_council: caseId,
      ...values,
      councilId: values.uid || "",
      state: (values.state && values.state.name) || "",
      city:
        (values.city &&
          Object.values(values.city).length > 0 &&
          Object.values(values.city)[0]) ||
        "",
      defendant: (values.defendant && JSON.stringify(values.defendant)) || "[]",
      isAdd: isAdd ? "1" : "0",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const removeOpposingCouncil = async (opposingId) => {
    const response = await axios.post(API_PATH.cases, {
      remove_opposing_counsel: opposingId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while removing the attorney! Please try again later."
    );
  };
  const updateCaseSignatory = async (signatory, caseId) => {
    const response = await axios.post(API_PATH.cases, {
      update_signatory: caseId,
      signatory,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later."
      );
    }
  };
  const shareCase = async (caseId, users) => {
    const response = await axios.post(API_PATH.cases, {
      share_case: caseId,
      users: JSON.stringify(users.map((user) => user.uid)),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later."
      );
    }
  };
  const acceptCase = async (caseId) => {
    const response = await axios.post(API_PATH.cases, {
      accept_case: caseId,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later."
      );
    }
  };
  const getCase = async (item) => {
    const response = await axios.post(API_PATH.cases, { case: item });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching your case! Please try again later."
    );
  };
  const getCases = async () => {
    const response = await axios.post(API_PATH.misc, { cases: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching your case! Please try again later."
    );
  };
  const getForm = async (formId) => {
    const response = await axios.post(API_PATH.cases, { formId });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching the form! Please try again later."
    );
  };
  const getResponse = async (responseId) => {
    const response = await axios.post(API_PATH.cases, { responseId });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching the response! Please try again later."
    );
  };
  const updateCase = async (values, uid) => {
    const attorney = values.attorney
      ? JSON.stringify(values.attorney.map((attorney) => attorney.uid))
      : "";
    const client = values.client
      ? JSON.stringify(values.client.map((cl) => cl.uid))
      : "";
    const response = await axios.post(API_PATH.cases, {
      update: uid,
      ...values,
      state: (values.state && values.state.name) || "",
      county:
        (values.county &&
          Object.values(values.county).length > 0 &&
          Object.values(values.county)[0]) ||
        "",
      attorney,
      client,
      plaintiff: JSON.stringify(values.plaintiff),
      defendant: JSON.stringify(values.defendant),
      oppState: (values.oppState && values.oppState.name) || "",
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update case details! Please try again later."
      );
    }
  };
  const updateCaseDate = async (
    dueDate,
    proofDate,
    daysProof,
    priority,
    uid
  ) => {
    const response = await axios.post(API_PATH.cases, {
      update_due: uid,
      dueDate,
      proofDate,
      daysProof,
      priority,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching your case! Please try again later."
    );
  };
  const updateCaseStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.cases, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update case status! Please try again later."
      );
    }
  };
  const deleteCase = async (remove) => {
    const response = await axios.post(API_PATH.cases, {
      remove,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to delete case! Please try again later."
      );
    }
  };
  const deleteForm = async (removeForm) => {
    const response = await axios.post(API_PATH.cases, {
      removeForm,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to delete form! Please try again later."
      );
    }
  };

  const getUsers = async (search) => {
    const response = await axios.post(API_PATH.misc, {
      users: search || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getClients = async (search) => {
    const response = await axios.post(API_PATH.misc, {
      clients: search || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getRoles = async (search) => {
    const response = await axios.post(API_PATH.misc, {
      roles: search || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getObjections = async (search, caseId) => {
    const response = await axios.post(API_PATH.misc, {
      objections: search || "",
      caseId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };

  const getObjection = async (objection) => {
    const response = await axios.post(API_PATH.objections, { objection });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching your objection! Please try again later."
    );
  };
  const getTemplate = async (template) => {
    const response = await axios.post(API_PATH.templates, { template });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching your template! Please try again later."
    );
  };
  const updateObjection = async (values, objection) => {
    const response = await axios.post(API_PATH.objections, {
      update: objection,
      ...values,
      state: values.state ? values.state.name : "",
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_objection") {
      throw new Error(
        "The objection you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update objection's details! Please try again later."
      );
    }
  };
  const updateTemplate = async (values, template) => {
    const response = await axios.post(API_PATH.templates, {
      update: template,
      ...values,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_template") {
      throw new Error(
        "The template you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to update template's details! Please try again later."
      );
    }
  };
  const deleteObjection = async (remove) => {
    const response = await axios.post(API_PATH.objections, {
      remove,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_objection") {
      throw new Error(
        "The objection you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to delete objection! Please try again later."
      );
    }
  };
  const deleteTemplate = async (remove) => {
    const response = await axios.post(API_PATH.templates, {
      remove,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_template") {
      throw new Error(
        "The template you selected does not exists or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to delete template! Please try again later."
      );
    }
  };
  const uploadFormFile = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data, fileName } = response.data;
    if (status === "success") {
      return { data, fileName };
    }
    throw new Error(
      "Something went wrong while attempting to upload file! Please try again later."
    );
  };
  const uploadTemplateFile = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data, fileName } = response.data;
    if (status === "success") {
      return { data, fileName };
    }
    throw new Error(
      "Something went wrong while attempting to upload file! Please try again later."
    );
  };
  const setFormFile = async (caseId, type, client, file, fileName) => {
    const response = await axios.post(API_PATH.cases, {
      set_file: caseId,
      file,
      fileName,
      type,
      client,
    });
    const { status, formId } = response.data;
    if (status === "success") {
      return formId;
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const processFile = async (
    caseId,
    type,
    client,
    form,
    isJudicial,
    fileName
  ) => {
    const response = await axios.post(API_PATH.misc, {
      process_file: caseId,
      type,
      form,
      client,
      isJudicial,
      fileName,
    });
    const { status, data, formId } = response.data;
    if (status === "success") {
      return isJudicial
        ? { data, form: formId }
        : saveProcessFile(caseId, type, form, JSON.stringify(data));
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "file_not_found") {
      throw new Error("Please upload a valid file to proceed.");
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const saveProcessFile = async (caseId, type, form, questions) => {
    const response = await axios.post(API_PATH.misc, {
      save_process_file: caseId,
      type,
      form,
      questions,
    });
    const { status, data, formId } = response.data;
    if (status === "success") {
      return { data, form: formId };
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "file_not_found") {
      throw new Error("Please upload a valid file to proceed.");
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const saveFormFile = async (caseId, type, client, form, questions) => {
    const response = await axios.post(API_PATH.cases, {
      save_file: caseId,
      type,
      form,
      client,
      questions: JSON.stringify(questions),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "file_not_found") {
      throw new Error("Please upload a valid file to proceed.");
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const createPos = async (caseId, type, form) => {
    const response = await axios.post(API_PATH.cases, {
      create_pos: caseId,
      type,
      form,
    });
    const { status, filename } = response.data;
    if (status === "success") {
      return filename;
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const createFile = async (caseId, type, form, fileType, isSendEmail) => {
    const response = await axios.post(API_PATH.cases, {
      create_file: caseId,
      type,
      form,
      fileType,
      isSendEmail,
    });
    const { status, filename } = response.data;
    if (status === "success") {
      return filename;
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const saveSignature = async (signature, adminId) => {
    const response = await axios.post(API_PATH.users, {
      signature,
      adminId,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };
  const translate = async (word, language) => {
    const response = await axios.post(API_PATH.misc, {
      translate: "",
      word,
      language,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return removeHTMLEntities(data);
    }
    return word;
  };
  const sendForm = async (
    caseId,
    type,
    form,
    message,
    shareAttorneyResponse,
    questions,
    language
  ) => {
    const response = await axios.post(API_PATH.cases, {
      send_file: caseId,
      type,
      form,
      message,
      shareAttorneyResponse,
      questions,
      language,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to complete this action! Please try again later."
      );
    }
  };
  const sendResponseForm = async (
    caseId,
    type,
    form,
    language,
    isSendToClient
  ) => {
    const response = await axios.post(API_PATH.cases, {
      send_response: caseId,
      type,
      form,
      language,
      isSendToClient,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to complete this action! Please try again later."
      );
    }
  };
  const saveFormAnswers = async (formId, answers) => {
    const response = await axios.post(API_PATH.cases, {
      save_answer: formId,
      answers: JSON.stringify(answers),
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to complete this action! Please try again later."
      );
    }
  };
  const saveClientSignature = async (
    formId,
    county,
    state,
    signature,
    corrections
  ) => {
    const response = await axios.post(API_PATH.cases, {
      save_client_signature: formId,
      signature,
      corrections,
      county:
        Object.values(county).length === 0 ? "" : Object.values(county)[0],
      state,
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to complete this action! Please try again later."
      );
    }
  };
  const saveAttorneyResponse = async (
    caseId,
    type,
    form,
    responses,
    finalizedAnswers,
    isDraft
  ) => {
    console.log(
      "puchini",
      finalizedAnswers,
      responses,
      JSON.stringify(finalizedAnswers)
    );
    const response = await axios.post(API_PATH.cases, {
      save_responses: caseId,
      form_type: type,
      form,
      is_draft: isDraft,
      finalizedAnswers: JSON.stringify(finalizedAnswers),
      responses: JSON.stringify(responses),
    });
    const { status } = response.data;
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to complete this action! Please try again later."
      );
    }
  };
  const saveFormComment = async (caseId, form, questionId, comment) => {
    const response = await axios.post(API_PATH.cases, {
      save_comment: caseId,
      form,
      questionId,
      comment,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "permission_denied") {
      throw new Error("You do not have permission to perform this action!");
    }
    if (status === "invalid_case") {
      throw new Error(
        "The case you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    if (status === "invalid_form") {
      throw new Error(
        "The form you selected does not exist or is invalid! Please select a valid one to proceed"
      );
    }
    throw new Error(
      "Something went wrong while trying to complete this action! Please try again later."
    );
  };

  const checkCouponCode = async (coupon) => {
    const response = await axios.post(API_PATH.subscription, {
      checkCoupon: coupon,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "expired_code") {
      throw new Error(
        "The coupon code you provided has expired! Please confirm and try again."
      );
    }
    if (status === "inactive_code") {
      throw new Error(
        "The coupon code you provided is not valid! Please confirm and try again."
      );
    }
    throw new Error(
      "The coupon code you provided does not exist! Please confirm and try again."
    );
  };
  const processSubscription = async (ref, deviceData, coupon) => {
    const response = await axios.post(API_PATH.subscription, {
      process: ref,
      deviceData,
      coupon,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while verifying your payment! Please contact us if you were debited."
    );
  };
  const getPaymentToken = async () => {
    const response = await axios.post(API_PATH.subscription, {
      generate: "",
    });
    const { status, token } = response.data;
    if (status === "success") {
      return token;
    }
    throw new Error("Something went wrong! Please try again later");
  };
  const cancelSubscription = async () => {
    const response = await axios.post(API_PATH.subscription, {
      cancel: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "insufficient_balance") {
      throw new Error(
        "Your subscription cancellation cannot be processed! Please fund your payment method and retry later."
      );
    }

    throw new Error(
      "Something went wrong while cancelling your subscription! Please try again later."
    );
  };
  const activateSubscription = async (reference, coupon) => {
    const response = await axios.post(API_PATH.subscription, {
      activate: reference,
      coupon,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "charge_failed") {
      throw new Error(
        reference
          ? "Something went wrong while trying to verify your payment! Please contact us if you were debited or try again later."
          : "Something went wrong while trying to charge your payment card! Please confirm and try again later."
      );
    }
    throw new Error(
      "Something went wrong while activating your subscription! Please try again later."
    );
  };
  const addCard = async (ref, deviceData) => {
    const response = await axios.post(API_PATH.subscription, {
      addCard: ref,
      deviceData,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while verifying your card! Please contact us if your wallet is not credited."
    );
  };
  const removeCard = async () => {
    const response = await axios.post(API_PATH.subscription, {
      removeCard: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while removing your card! Please try again later,."
    );
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        saveToken,
        login,
        logout,
        updateSettings,
        sendPasswordVerify,
        forgotPassword,
        addCard,
        activateSubscription,
        resetPassword,
        addSupport,
        sendVerifyCode,
        verifyAccount,
        initialize,
        requestLink,
        removeCard,
        signup,
        cancelSubscription,
        getClient,
        getUser,
        getRoles,
        addObjection,
        saveSignature,
        createPos,
        createFile,
        uploadTemplateFile,
        getCase,
        getCases,
        addClient,
        getUsers,
        getClients,
        addCase,
        addUser,
        updateClientStatus,
        updateClient,
        deleteClient,
        updateUserStatus,
        updateUser,
        deleteUser,
        deleteObjection,
        getPaymentToken,
        getObjection,
        updateCaseOpposingCouncil,
        updateCaseSignatory,
        updateObjection,
        updateCaseDate,
        processSubscription,
        checkCouponCode,
        updateCaseStatus,
        deleteCase,
        deleteForm,
        updateCase,
        uploadFormFile,
        setFormFile,
        processFile,
        saveFormFile,
        sendForm,
        getForm,
        getResponse,
        saveFormAnswers,
        saveClientSignature,
        verifyForm,
        sendFormVerifyCode,
        sendResponseForm,
        getObjections,
        saveAttorneyResponse,
        saveFormComment,
        addTemplate,
        getTemplate,
        deleteTemplate,
        updateTemplate,
        updateUserPassword,
        translate,
        removeOpposingCouncil,
        shareCase,
        acceptCase,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
