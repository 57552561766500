import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import clientReducer from "./slices/client";
import userReducer from "./slices/user";
import caseReducer from "./slices/case";
import objectionReducer from "./slices/objection";
import orderReducer from "./slices/order";
import logReducer from "./slices/log";
import supportReducer from "./slices/support";
import templateReducer from "./slices/template";
import discoveryReducer from "./slices/discovery";
import billingReducer from "./slices/billing";
import globalReducer from "./slices/global";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const globalPersistConfig = {
  key: "global",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sales"],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  client: clientReducer,
  log: logReducer,
  user: userReducer,
  billing: billingReducer,
  support: supportReducer,
  case: caseReducer,
  order: orderReducer,
  objection: objectionReducer,
  template: templateReducer,
  discovery: discoveryReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
});

export { rootPersistConfig, rootReducer };
